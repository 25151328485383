var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-station" },
    [
      _c("div", { staticClass: "base-station_top" }, [
        _c("div", { staticClass: "search" }, [
          _c(
            "div",
            { staticClass: "search_input" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("baseStation.name")) + "：")]),
              _c("el-input", {
                attrs: { size: "small", placeholder: _vm.$t("search") },
                model: {
                  value: _vm.searchForm.stationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "stationName", $$v)
                  },
                  expression: "searchForm.stationName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search_btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchData },
                },
                [_vm._v(_vm._s(_vm.$t("searchBtn")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-delete", size: "small" },
                  on: { click: _vm.clearSearch },
                },
                [_vm._v(_vm._s(_vm.$t("emptyBtnText")))]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "operation" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
                on: { click: _vm.addStation },
              },
              [_vm._v(_vm._s(_vm.$t("btnAdd")))]
            ),
            _c("el-button", {
              attrs: { icon: "el-icon-refresh", size: "small", circle: "" },
              on: { click: _vm.getStationData },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "base-station_table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "", height: "100%" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  type: "selection",
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  type: "index",
                  label: _vm.$t("index"),
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("baseStation.name"),
                  align: "center",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: _vm.$t("baseStation.address"),
                  align: "center",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "baseStationIp",
                  label: _vm.$t("baseStation.baseStationIp"),
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "edgeServerAdHocIp",
                  label: _vm.$t("baseStation.edgeServerAdHocIp"),
                  align: "center",
                  "min-width": "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "edgeServerLanIp",
                  label: _vm.$t("baseStation.edgeServerLanIp"),
                  align: "center",
                  "min-width": "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "communicationPort",
                  label: _vm.$t("baseStation.communicationPort"),
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("longitudeAndLatitude"),
                  align: "center",
                  "min-width": "170",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.lonLat))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("baseStation.type"),
                  align: "center",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.stationTypeList.find(function (t) {
                                return t.dictKey == scope.row.type
                              }).dictValue
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("noFlyZone.createdTime"),
                  align: "center",
                  "min-width": "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("airportManager.operation"),
                  align: "center",
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-view" },
                            on: {
                              click: function ($event) {
                                return _vm.viewData(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("view")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.editData(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("edit")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteData(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("delete")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "base-station_page" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pageForm.page,
              "page-sizes": _vm.pageForm.pageSizes,
              "page-size": _vm.pageForm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              background: "",
              total: _vm.pageForm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.stationPop.title,
            visible: _vm.stationPop.isShow,
            width: "800px",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.stationPop, "isShow", $event)
            },
            "before-close": _vm.closeStationPop,
          },
        },
        [
          _c(
            "div",
            { staticClass: "station_content" },
            [
              _c(
                "el-form",
                {
                  ref: "stationForm",
                  staticClass: "station-ruleForm",
                  attrs: {
                    model: _vm.stationForm,
                    rules: _vm.stationRules,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("baseStation.name") + "：",
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              disabled: _vm.stationPop.isView,
                              placeholder: _vm.$t("baseStation.name"),
                            },
                            model: {
                              value: _vm.stationForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.stationForm, "name", $$v)
                              },
                              expression: "stationForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("baseStation.type") + "：",
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                clearable: "",
                                disabled: _vm.stationPop.isView,
                                placeholder: _vm.$t("baseStation.type"),
                              },
                              model: {
                                value: _vm.stationForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.stationForm, "type", $$v)
                                },
                                expression: "stationForm.type",
                              },
                            },
                            _vm._l(_vm.stationTypeList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("baseStation.address") + "：",
                            prop: "address",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              disabled: _vm.stationPop.isView,
                              placeholder: _vm.$t("baseStation.address"),
                            },
                            model: {
                              value: _vm.stationForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.stationForm, "address", $$v)
                              },
                              expression: "stationForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("baseStation.baseStationIp") + "：",
                            prop: "baseStationIp",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              disabled: _vm.stationPop.isView,
                              placeholder: _vm.$t("baseStation.baseStationIp"),
                            },
                            model: {
                              value: _vm.stationForm.baseStationIp,
                              callback: function ($$v) {
                                _vm.$set(_vm.stationForm, "baseStationIp", $$v)
                              },
                              expression: "stationForm.baseStationIp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("baseStation.edgeServerAdHocIp") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              disabled: _vm.stationPop.isView,
                              placeholder: _vm.$t(
                                "baseStation.edgeServerAdHocIp"
                              ),
                            },
                            model: {
                              value: _vm.stationForm.edgeServerAdHocIp,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.stationForm,
                                  "edgeServerAdHocIp",
                                  $$v
                                )
                              },
                              expression: "stationForm.edgeServerAdHocIp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("baseStation.edgeServerLanIp") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              disabled: _vm.stationPop.isView,
                              placeholder: _vm.$t(
                                "baseStation.edgeServerLanIp"
                              ),
                            },
                            model: {
                              value: _vm.stationForm.edgeServerLanIp,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.stationForm,
                                  "edgeServerLanIp",
                                  $$v
                                )
                              },
                              expression: "stationForm.edgeServerLanIp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("baseStation.communicationPort") + "：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              disabled: _vm.stationPop.isView,
                              placeholder: _vm.$t(
                                "baseStation.communicationPort"
                              ),
                            },
                            model: {
                              value: _vm.stationForm.communicationPort,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.stationForm,
                                  "communicationPort",
                                  $$v
                                )
                              },
                              expression: "stationForm.communicationPort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("longitudeAndLatitude") + "：",
                            prop: "lonLat",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "local_jwd",
                              class: {
                                disabled: _vm.stationPop.isView,
                                placeholder: !_vm.stationForm.lonLat,
                              },
                              attrs: { title: _vm.stationForm.lonLat },
                              on: {
                                click: function ($event) {
                                  return _vm.openMapPop(_vm.stationForm)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.stationForm.lonLat ||
                                      _vm.$t("longitudeAndLatitude")
                                  )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.stationPop.isView
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: _vm.stationPop.isEdit
                          ? "el-icon-circle-check"
                          : "el-icon-circle-plus-outline",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitStation("stationForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("submitText")))]
                  )
                : _vm._e(),
              _vm.stationPop.isView
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-circle-close",
                      },
                      on: { click: _vm.closeStationPop },
                    },
                    [_vm._v(_vm._s(_vm.$t("closeText")))]
                  )
                : _vm._e(),
              !_vm.stationPop.isView
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", icon: "el-icon-circle-close" },
                      on: { click: _vm.closeStationPop },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancelText")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "map_dialog",
          attrs: {
            title: _vm.$t("baseStation.selectLocation"),
            visible: _vm.mapPop.isShow,
            width: "60%",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.mapPop, "isShow", $event)
            },
            "before-close": _vm.closeMapPop,
          },
        },
        [
          _c("div", { staticClass: "map_content" }, [
            _c(
              "div",
              { staticClass: "local_msg" },
              [
                _c("el-input", {
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: _vm.$t("baseStation.selectLocationTip"),
                  },
                  on: { blur: _vm.changeLonLat },
                  model: {
                    value: _vm.mapForm.lonLat,
                    callback: function ($$v) {
                      _vm.$set(_vm.mapForm, "lonLat", $$v)
                    },
                    expression: "mapForm.lonLat",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "cesiumMap",
                class: { draw: _vm.mapPop.isDraw },
                attrs: { id: "cesiumMap" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "draw_box",
                    class: { active: _vm.mapPop.isDraw },
                    on: { click: _vm.drawStation },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.mapPop.isDraw,
                          expression: "!mapPop.isDraw",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/img/map/station.png"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mapPop.isDraw,
                          expression: "mapPop.isDraw",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/img/map/station-active.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-circle-check",
                  },
                  on: { click: _vm.submitMap },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-circle-close" },
                  on: { click: _vm.closeMapPop },
                },
                [_vm._v(_vm._s(_vm.$t("cancelText")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
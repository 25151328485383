<template>
    <!-- 基站列表 -->
    <div class="base-station">
        <div class="base-station_top">
            <div class="search">
                <div class="search_input">
                    <label>{{$t('baseStation.name')}}：</label>
                    <el-input size="small" v-model="searchForm.stationName" :placeholder="$t('search')" />
                </div>
                <div class="search_btn">
                    <el-button type="primary" size="small" icon="el-icon-search" 
                        @click="searchData">{{$t('searchBtn')}}</el-button>
                    <el-button icon="el-icon-delete" size="small" 
                        @click="clearSearch">{{$t('emptyBtnText')}}</el-button>
                </div>
            </div>
            <div class="operation">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addStation">{{$t('btnAdd')}}</el-button>
                <el-button icon="el-icon-refresh" size="small" circle @click="getStationData"></el-button>
            </div>
        </div>
        <div class="base-station_table">
            <el-table :data="tableData" border style="width: 100%" height="100%">
                <el-table-column fixed type="selection" align="center" width="50" />
                <el-table-column fixed type="index" :label="$t('index')" align="center" width="50" />
                <el-table-column prop="name" :label="$t('baseStation.name')" align="center" min-width="120" />
                <el-table-column prop="address" :label="$t('baseStation.address')" align="center" min-width="120" />
                <el-table-column prop="baseStationIp" :label="$t('baseStation.baseStationIp')" align="center" min-width="100" />
                <el-table-column prop="edgeServerAdHocIp" :label="$t('baseStation.edgeServerAdHocIp')" align="center" min-width="150" />
                <el-table-column prop="edgeServerLanIp" :label="$t('baseStation.edgeServerLanIp')" align="center" min-width="150" />
                <el-table-column prop="communicationPort" :label="$t('baseStation.communicationPort')" align="center" min-width="100" />
                <el-table-column :label="$t('longitudeAndLatitude')" align="center" min-width="170">
                    <template slot-scope="scope">
                        <span>{{scope.row.lonLat}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('baseStation.type')" align="center" min-width="90">
                    <template slot-scope="scope">
                        <span>{{stationTypeList.find(t => t.dictKey == scope.row.type).dictValue}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" :label="$t('noFlyZone.createdTime')" align="center" min-width="150" />
                <el-table-column fixed="right" :label="$t('airportManager.operation')" align="center" width="220">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-view" @click="viewData(scope.row)">{{$t('view')}}</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="editData(scope.row)">{{$t('edit')}}</el-button>
                        <el-button type="text" icon="el-icon-delete" @click="deleteData(scope.row)">{{$t('delete')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="base-station_page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pageForm.page" :page-sizes="pageForm.pageSizes" :page-size="pageForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" background :total="pageForm.total" />
        </div>
        <!-- 新增编辑基站弹窗 -->
        <el-dialog :title="stationPop.title" :visible.sync="stationPop.isShow" width="800px" :modal-append-to-body="false" 
            :close-on-click-modal="false" :close-on-press-escape="false" @before-close="closeStationPop">
            <div class="station_content">
                <el-form :model="stationForm" :rules="stationRules" ref="stationForm" label-width="130px" 
                    class="station-ruleForm">
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.name') + '：'" prop="name">
                            <el-input v-model="stationForm.name" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.name')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.type') + '：'" prop="type">
                            <el-select v-model="stationForm.type" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.type')">
                                <el-option v-for="item in stationTypeList" :key="item.id" 
                                    :label="item.dictValue" :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.address') + '：'" prop="address">
                            <el-input v-model="stationForm.address" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.address')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.baseStationIp') + '：'" prop="baseStationIp">
                            <el-input v-model="stationForm.baseStationIp" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.baseStationIp')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.edgeServerAdHocIp') + '：'">
                            <el-input v-model="stationForm.edgeServerAdHocIp" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.edgeServerAdHocIp')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.edgeServerLanIp') + '：'">
                            <el-input v-model="stationForm.edgeServerLanIp" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.edgeServerLanIp')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('baseStation.communicationPort') + '：'">
                            <el-input v-model="stationForm.communicationPort" size="small" 
                                clearable :disabled="stationPop.isView" :placeholder="$t('baseStation.communicationPort')" />
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item :label="$t('longitudeAndLatitude') + '：'" prop="lonLat">
                            <div class="local_jwd" :class="{'disabled': stationPop.isView, 
                                'placeholder': !stationForm.lonLat}" :title="stationForm.lonLat" 
                                @click="openMapPop(stationForm)">
                                {{stationForm.lonLat || $t('longitudeAndLatitude')}}</div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="!stationPop.isView" size="small" type="primary" 
                    :icon="stationPop.isEdit ? 'el-icon-circle-check' : 'el-icon-circle-plus-outline'"
                    @click="submitStation('stationForm')">{{$t('submitText')}}</el-button>
                <el-button v-if="stationPop.isView" size="small" type="primary" icon="el-icon-circle-close"
                    @click="closeStationPop">{{$t('closeText')}}</el-button>
                <el-button v-if="!stationPop.isView" size="small" icon="el-icon-circle-close"
                    @click="closeStationPop">{{$t('cancelText')}}</el-button>
            </span>
        </el-dialog>
        <!-- 选择地点经纬度弹窗 -->
        <el-dialog :title="$t('baseStation.selectLocation')" :visible.sync="mapPop.isShow" width="60%" :modal-append-to-body="false" 
            :close-on-click-modal="false" :close-on-press-escape="false" @before-close="closeMapPop" class="map_dialog">
            <div class="map_content">
                <div class="local_msg">
                    <el-input v-model="mapForm.lonLat" size="small" clearable 
                        :placeholder="$t('baseStation.selectLocationTip')" @blur="changeLonLat" />
                </div>
                <div class="cesiumMap" :class="{'draw': mapPop.isDraw}" id="cesiumMap">
                    <div class="draw_box" :class="{'active': mapPop.isDraw}" @click="drawStation">
                        <img v-show="!mapPop.isDraw" src="@/assets/img/map/station.png" alt="">
                        <img v-show="mapPop.isDraw" src="@/assets/img/map/station-active.png" alt="">
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" icon="el-icon-circle-check" 
                    @click="submitMap">{{$t('submitText')}}</el-button>
                <el-button size="small" icon="el-icon-circle-close" @click="closeMapPop">{{$t('cancelText')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import NodeMap from "@/components/cesium/js/node/index";
import NormalOperation from '@/components/cesium/js/interactive/operation/NormalOperation';
import stationIcon from "@/assets/img/map/station.png";
import {getStationList, addEditStation, deleteStation} from "@/api/phugiacloud/baseStationManagement";
import {getDictionary} from "@/api/system/dict";

export default {
    name: 'base-stationManagement',
    data() {
        return {
            viewModel: Cesium.SceneMode.SCENE3D,
            searchForm: {stationName: ''},
            tableData: [],
            pageForm: {
                page: 1,
                pageSize: 10,
                pageSizes: [10, 20, 30, 40, 50, 100],
                total: 0
            },
            stationPop: {
                title: this.$t('baseStation.addBaseStation'),
                isShow: false,
                isView: false,
                isEdit: false
            },
            stationRules: {
                name: [
                    {required: true, message: this.$t('baseStation.name'), trigger: 'blur'}
                ],
                type: [
                    {required: true, message: this.$t('baseStation.type'), trigger: 'change'}
                ],
                address: [
                    {required: true, message: this.$t('baseStation.address'), trigger: 'blur'}
                ],
                baseStationIp: [
                    {required: true, message: this.$t('baseStation.baseStationIp'), trigger: 'blur'}
                ],
                lonLat: [
                    {trigger: 'blur', validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error(this.$t('baseStation.selectLocation')));
                        }
                        const lntlat = value.split(',');
                        if (!lntlat[0] || !lntlat[1] || !Number(lntlat[0]) || !Number(lntlat[1])) {
                            return callback(new Error(this.$t('baseStation.selectLocationError')));
                        }
                        callback();
                    }}
                ]
            },
            stationTypeList: [],
            stationForm: {},
            mapPop: {
                isShow: false,
                isDraw: false
            },
            mapForm: {
                lonLat: ''
            }
        }
    },
    created() {
        this.getStationType();
    },
    methods: {
        // 获取基站类型
        getStationType() {
            getDictionary({code: 'basestationType'}).then(res => {
                if (res.data.code == 200) {
                    this.stationTypeList = res.data.data || [];
                }
            }).finally(() => {
                this.getStationData();
            });
        },
        // 获取基站数据
        getStationData() {
            let params = {
                name: this.searchForm.stationName,
                current: this.pageForm.page,
                size: this.pageForm.pageSize
            };
            this.tableData = [];
            getStationList(params).then(res => {
                if (res.data.code == 200) {
                    this.pageForm.total = res.data.data.total;
                    if (res.data.data.records) {
                        this.tableData = res.data.data.records.map(t => {
                            const coordinates = t.content ? t.content.coordinates : ['', '']
                            t.lonLat = `${coordinates[0]},${coordinates[1]}`;
                            t.type = `${t.type}`;
                            return t;
                        });
                    }
                }
            });
        },
        // 搜索
        searchData() {
            this.pageForm.page = 1;
            this.getStationData();
        },
        // 清空搜索
        clearSearch() {
            this.pageForm.page = 1;
            this.searchForm = {stationName: ''};
            this.searchData();
        },
        // 改变每页条数
        handleSizeChange(val) {
            this.pageForm.page = 1;
            this.pageForm.pageSize = val;
            this.getStationData();
        },
        // 改变页码
        handleCurrentChange(val) {
            this.pageForm.page = val;
            this.getStationData();
        },
        // 查看基站数据
        viewData(data) {
            this.stationForm = JSON.parse(JSON.stringify(data));
            this.stationPop = {
                title: this.$t('baseStation.viewBaseStation'),
                isShow: true,
                isView: true,
                isEdit: false
            };
        },
        // 编辑基站数据
        editData(data) {
            this.stationForm = JSON.parse(JSON.stringify(data));
            this.stationPop = {
                title: this.$t('baseStation.editBaseStation'),
                isShow: true,
                isView: false,
                isEdit: true
            };
        },
        // 删除基站数据
        deleteData(data) {
            this.$confirm(this.$t('baseStation.deleteTip'), this.$t('tip'), {
                confirmButtonText: this.$t('submitText'),
                cancelButtonText: this.$t('cancelText'),
                type: 'warning'
            }).then(() => {
                deleteStation({ids: data.id}).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success(this.$t('deleteSuccessTip'));
                        this.getStationData();
                    } else {
                        this.$message.error(this.$t('deleteErrorTip'));
                    }
                });
            });
        },
        // 新增基站
        addStation() {
            this.stationForm = {
                name: '',
                type: '',
                address: '',
                baseStationIp: '',
                edgeServerAdHocIp: '',
                edgeServerLanIp: '',
                communicationPort: '',
                lonLat: ''
            };
            this.stationPop = {
                title: this.$t('baseStation.addBaseStation'),
                isShow: true,
                isView: false,
                isEdit: false
            };
        },
        // 关闭基站弹窗
        closeStationPop() {
            this.stationPop = {
                title: this.$t('baseStation.addBaseStation'),
                isShow: false,
                isView: false,
                isEdit: false
            };
            this.$refs.stationForm.resetFields();
            this.stationForm = {};
        },
        // 保存基站数据
        submitStation(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let stationForm = JSON.parse(JSON.stringify(this.stationForm));
                    const lntlat = stationForm.lonLat.split(',');
                    stationForm.content = {
                        type: 'Feature',
                        geometry: {type: 'Point', coordinates: [lntlat[0] * 1, lntlat[1] * 1]},
                        properties: {color: ''}
                    }
                    addEditStation(stationForm).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success(this.$t('saveSuccessTip'));
                            this.closeStationPop();
                            this.getStationData();
                        } else {
                            this.$message.error(this.$t('saveErrorTip'));
                        }
                    }).catch(() => {
                        this.$message.error(this.$t('saveErrorTip'));
                    }).finally(() => {
                        // 
                    });
                }
            });
        },
        // 打开选择地点经纬度弹窗
        openMapPop(data) {
            if (this.stationPop.isView) return;
            this.mapForm = {lonLat: data.lonLat};
            this.mapPop = {isShow: true, isDraw: false};
            this.$nextTick(() => {
                const lntlat = data.lonLat ? data.lonLat.split(',') : '';
                this.initMap(lntlat);
            });
        },
        // 改变坐标点失焦触发
        changeLonLat() {
            if (!this.mapForm.lonLat) {
                return this.$message.warning(this.$t('baseStation.lonlatErrorTip1'));
            }
            const lntlat = this.mapForm.lonLat.split(',');
            if (!lntlat[0] || !lntlat[1] || !Number(lntlat[0]) || !Number(lntlat[1])) {
                return this.$message.warning(this.$t('baseStation.lonlatErrorTip2'));
            }
            this.createStationMark(lntlat);
        },
        // 关闭选择地点经纬度弹窗
        closeMapPop() {
            this.mapPop = {isShow: false, isDraw: false};
            this.clearStationLayer();
            this.$options.map3d._container.style.cursor = "default";
            this.removeLeftClickEvent();
            this.mapForm = {lonLat: ''};
        },
        // 保存地点经纬度弹窗信息
        submitMap() {
            if (!this.mapForm.lonLat) {
                return this.$message.warning(this.$t('baseStation.lonlatErrorTip1'));
            }
            const lntlat = this.mapForm.lonLat.split(',');
            if (!lntlat[0] || !lntlat[1] || !Number(lntlat[0]) || !Number(lntlat[1])) {
                return this.$message.warning(this.$t('baseStation.lonlatErrorTip2'));
            }
            this.stationForm.lonLat = this.mapForm.lonLat;
            this.closeMapPop();
        },
        // 初始化地图
        initMap(point) {
            if (!this.$options.map3d) {
                const viewer = new Map3d('cesiumMap', {
                    sceneMode: this.viewModel,
                });
                this.$options.map3d = viewer;
            }
            // 初始化layer
            if (!this.$options.stationLayer) {
                this.$options.stationLayer = new BaseLayer({name: 'localStation', id: 'localStation'});
                this.$options.map3d.layerManager.add(this.$options.stationLayer);
            }
            if (this.$options.map3d && this.$options.stationLayer && point) {
                this.createStationMark(point);
            }
        },
        // 绘制基站点
        createStationMark(point) {
            this.clearStationLayer();
            let position = Cesium.Cartesian3.fromDegrees(point[0] * 1, point[1] * 1, 0);
            const stationEntity = new NodeMap.Point({
                position,
                style: {
                    id: '41c1s1cv5sd1vsd',
                    position,
                    label: {
                        text: this.$t('baseStation.baseStation'),
                        scale: 0.5,
                        showBackground: true,
                        backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                        backgroundPadding: new Cesium.Cartesian2(16, 8),
                        font: "28px sans-serif",
                        pixelOffset: new Cesium.Cartesian2(0, 32),
                        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 1000 * 50)
                    },
                    billboard: {
                        image: stationIcon,
                        width: 30,
                        height: 40,
                        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 1000 * 1000 * 2.8)
                    }
                }
            });
            this.$options.stationLayer.addNode(stationEntity);
            this.locationToEntity(stationEntity.entity);
            this.setEditModel(this.$options.map3d);
        },
        setEditModel(map3d) {
            map3d._container.style.cursor = "default";
            let afterFinish = () => {};
            let params = {
                map: map3d,
                vueHandler: this,
                afterFinish,
                canEdit: false
            };
            map3d.triggerEditModel(new NormalOperation(params));
        },
        // 定位基站实体
        locationToEntity(entity) {
            if (!entity) return;
            // this.$options.map3d.flyTo(entity, {
            //     offset: new Cesium.HeadingPitchRange(0, -90, 4000),
            //     duration: 1.5
            // });
            this.$options.map3d.zoomTo(
                entity,
                new Cesium.HeadingPitchRange(0, -90, 4000)
            );
        },
        // 选择初始化绘制设置
        drawStation() {
            this.mapPop.isDraw = !this.mapPop.isDraw;
            if (this.mapPop.isDraw) {
                this.$options.map3d._container.style.cursor = "crosshair";
                this.removeLeftClickEvent();
                this.addLeftClickEvent();
            } else {
                this.$options.map3d._container.style.cursor = "default";
                this.removeLeftClickEvent();
            }
        },
        // 添加左键事件
        addLeftClickEvent() {
            let _this = this;
            if (!this.$options.handlerLeftClick) {
                this.$options.handlerLeftClick = new Cesium.ScreenSpaceEventHandler(this.$options.map3d.scene.canvas);
                this.$options.handlerLeftClick.setInputAction(e => {
                    _this.drawStation();
                    const cartain2 = new Cesium.Cartesian2(e.position.x, e.position.y);
                    const cartain3 = this.$options.map3d.scene.globe.pick(this.$options.map3d.camera.getPickRay(cartain2), 
                        this.$options.map3d.scene);
                    const cartographic = Cesium.Cartographic.fromCartesian(cartain3);
                    const lon = Cesium.Math.toDegrees(cartographic.longitude);
			        const lat = Cesium.Math.toDegrees(cartographic.latitude);
                    _this.createStationMark([lon, lat]);
                    this.mapForm.lonLat = `${lon}, ${lat}`;
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
            }
        },
        // 移除左键事件
        removeLeftClickEvent() {
            if (this.$options.handlerLeftClick) {
                this.$options.handlerLeftClick.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                this.$options.handlerLeftClick = null;
            }
        },
        // 清理基站点
        clearStationLayer() {
            this.$options.stationLayer && this.$options.stationLayer.clear();
        }
    },
    beforeDestroy() {
        if (this.$options.map3d && this.$options.stationLayer) {
            this.$options.stationLayer.clear();
            this.$options.map3d.layerManager.remove(this.$options.stationLayer, true);
            this.$options.map3d.destroy();
        }
    }
}
</script>

<style lang="scss" scoped>
.base-station {
    height: 100%;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    color: #303133;
    -webkit-transition: .3s;
    transition: .3s;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .base-station_top {
        padding-top: 20px;
        padding-bottom: 12px;
        .search {
            display: flex;
            flex: 1;
            .search_input {
                display: flex;
                align-items: center;
                width: 23%;
                label {
                    width: 130px;
                    text-align: right;
                    font-size: 14px;
                    color: #606266;
                }
            }
            .search_btn {
                margin-left: 20px;
            }
        }
        .operation {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
    }
    .base-station_table {
        flex: 1;
        ::v-deep {
            .el-table {
                th {
                    background-color: #FAFAFA;
                    color: #000;
                    font-size: 13px;
                }
                td {
                    font-size: 13px;
                    .el-button--text {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .base-station_page {
        padding: 20px 0;
        text-align: right;
    }
    ::v-deep .el-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #f0f0f0;
        }
        .el-dialog__body {
            .station-ruleForm {
                display: flex;
                flex-wrap: wrap;
                .form-item {
                    width: 50%;
                    .el-form-item {
                        display: flex;
                        align-items: center;
                        .el-form-item__label {
                            line-height: 18px;
                        }
                        .el-form-item__content {
                            flex: 1;
                            margin-left: 0 !important;
                            .el-select {
                                width: 100%;
                            }
                            .el-input.is-disabled .el-input__inner {
                                background-color: #ffffff;
                                color: #606266;
                            }
                        }
                        .local_jwd {
                            height: 32px;
                            line-height: 32px;
                            color: #606266;
                            font-size: 13px;
                            padding: 0 15px;
                            background-color: #ffffff;
                            border-radius: 4px;
                            border: 1px solid #DCDFE6;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            &.disabled {
                                cursor: not-allowed;
                            }
                            &.placeholder {
                                color: #C0C4CC;
                            }
                        }
                    }
                }
            }
        }
    }
    .map_dialog {
        ::v-deep .el-dialog {
            margin-top: 8vh !important;
            .el-dialog__body {
                padding: 0 0 10px 0;
                .map_content {
                    height: 480px;
                    display: flex;
                    flex-direction: column;
                    .local_msg {
                        padding: 10px 20px;
                    }
                    .cesiumMap {
                        width: 100%;
                        height: 0;
                        flex: 1;
                        position: relative;
                        .draw_box {
                            width: 26px;
                            height: 31px;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            z-index: 999;
                            cursor: pointer;
                            border: 2px solid #DCDFE6;
                            border-radius: 4px;
                            padding: 5px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                            &.active {
                                border: 2px solid #409EFF;
                            }
                        }
                        .cesium-widget canvas {
                            cursor: default !important;
                        }
                        &.draw {
                            .cesium-widget canvas {
                                cursor: crosshair !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>